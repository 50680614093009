import clsx from "clsx";
import PropTypes from "prop-types";
import * as React from "react";
import { Product } from "../../../domain";
import { PreloadLink } from "../../../theme";
import { ProductImage, ProductText } from "../../Product";

export default function ProductCard({
  data: product,
  isDark = true,
  isExpanded,
  style,
}) {
  return (
    <div style={style}>
      <PreloadLink
        className={clsx("preload-link-product", isExpanded && "expanded")}
        to={`/${product.route}/${product.productId}`}
        endpoints={[`/api/influencer/byRoute/${product.route}`]}
      >
        <ProductImage isDark={isDark} isStorefront product={product} />
        <ProductText isDark={isDark} product={product} isStorefront />
      </PreloadLink>
    </div>
  );
}

ProductCard.propTypes = {
  data: PropTypes.instanceOf(Product),
  isDark: PropTypes.bool,
  isExpanded: PropTypes.bool,
  isMobile: PropTypes.bool,
  style: PropTypes.object,
};
