import { Dialog, IconButton } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { Cart, Influencer, Product } from "../../../../domain";
import { useCart, useCurrentUser } from "../../../../hooks";
import { useEmailModal } from "../../../../services/EmailModal";
import track from "../../../../services/UserTrackingActions";
import { scrollbarStyles } from "../../../../theme";
import { getParameter, localStorageUtils } from "../../../../util";
import ProductModalActions from "./ProductModalActions";
import ProductModalContent from "./ProductModalContent";
import { createInitialState, productModalReducer } from "./state";

const noop = (evt) => {
  evt?.preventDefault?.();
  evt?.stopPropagation?.();
};

const ProductModal = React.memo(function ProductModal({
  product,
  open,
  onClose,
  influencer,
  pageRoute,
  pageName,
}) {
  const [errorMessage, setErrorMessage] = React.useState("");
  const addedRef = React.useRef();
  const history = useHistory();
  const user = useCurrentUser();
  const { refresh, isLoading } = useCart();
  const { block, unblock } = useEmailModal();

  React.useEffect(() => {
    block();

    return () => {
      unblock();
    };
  }, [block, unblock]);

  const [state, dispatch] = React.useReducer(
    productModalReducer,
    createInitialState(),
  );

  const wrappedOnClose = React.useCallback(
    (...args) => {
      Cart.clear();
      if (influencer?.route) {
        history.replace(`/${influencer.route}`);
      }
      onClose(...args);
    },
    [onClose, history, influencer?.route],
  );

  const handleCheckout = React.useCallback(
    async (evt) => {
      evt.preventDefault();

      if (!influencer && !pageRoute && !pageName) {
        return;
      }

      const { notes, personalizationTo } = state;

      const trackingCode =
        getParameter("stc") || localStorageUtils.getWithExpiry("stc");

      await Promise.all([
        Cart.update({
          influencerId: influencer?.influencerId ?? product.influencerId,
          trackingCode,
        }),
        Cart.updateProduct({
          productId: product.productId,
          personalization: {
            notes,
            personalizationTo,
            personalizationAddonCharacterName: "",
            personalizationAddonQuote: "",
          },
        }),
      ]);

      refresh();

      onClose(() => history.push("/checkout"));
    },
    [
      influencer,
      pageRoute,
      pageName,
      state,
      product,
      refresh,
      onClose,
      history,
    ],
  );

  React.useEffect(() => {
    if (isLoading || !product || addedRef.current) {
      return;
    }

    addedRef.current = true;

    const addToCart = async () => {
      try {
        await Cart.clear();

        const { errors } = await Cart.addProduct({
          productId: product.productId,
        });

        if (Array.isArray(errors) && errors.length) {
          const [err] = errors;

          setErrorMessage(err.message);

          return;
        }

        setErrorMessage("");

        await refresh();

        await track({
          userId: user.userId,
          actionType: "product-view",
          actionMetaData: {
            shopInfluencerId: influencer?.influencerId ?? null,
            shopRoute: influencer?.route ?? pageRoute,
            shopName: influencer?.name ?? pageName,
            productId: product.productId,
            productName: product.name,
            productPrice: product.subtotal.value,
          },
        });
      } catch (err) {
        console.error(err);
        setErrorMessage(err.message);
      }
    };

    addToCart();
  }, [influencer, isLoading, product, user, refresh, pageRoute, pageName]);

  React.useEffect(() => {
    // display url for product displayed
    if (!influencer || !product) {
      return;
    }

    history.replace(`/${influencer.route}/${product.productId}`);
  }, [influencer, product, history]);

  return (
    <StyledDialog open={open} onClose={wrappedOnClose}>
      <StyledIconButton onClick={wrappedOnClose} aria-label="Close">
        <CloseIcon />
      </StyledIconButton>

      <form onSubmit={errorMessage ? noop : handleCheckout}>
        <ProductModalContent
          dispatch={dispatch}
          influencer={influencer}
          product={product}
          state={state}
        />

        <ProductModalActions product={product} errorMessage={errorMessage} />
      </form>
    </StyledDialog>
  );
});

ProductModal.propTypes = {
  product: PropTypes.instanceOf(Product),
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  influencer: PropTypes.instanceOf(Influencer),
  pageRoute: PropTypes.string,
  pageName: PropTypes.string,
};

const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": { paddingBottom: 0 },
  "& .MuiPaper-root": {
    borderRadius: theme.spacing(0.5),
    maxWidth: "64rem",
    minHeight: "18rem",
    [theme.breakpoints.down("xs")]: {
      maxWidth: "unset",
      width: "100vw",
      margin: 0,
    },
  },
  "& .MuiDialog-paperScrollPaper": {
    ...scrollbarStyles(theme),
  },
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  position: "absolute",
  left: theme.spacing(0.5),
  top: theme.spacing(0.5),
  zIndex: 10,
}));

export default ProductModal;
