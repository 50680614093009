import * as React from "react";
import { F } from "@mobily/ts-belt";
import { useLocation } from "react-router-dom";

export default function useQuery(getter = F.identity) {
  const location = useLocation();
  const { search } = location;

  return React.useMemo(() => {
    if (!search) {
      return getter(new URLSearchParams());
    }

    return getter(new URLSearchParams(search.substring(1)));
  }, [getter, search]);
}
