import { Card, CardMedia } from "@material-ui/core";
import { styled } from "@material-ui/core/styles";

export const StyledCard = styled(Card)(({ theme }) => ({
  minWidth: 480,
  maxWidth: theme.breakpoints.values.sm,
  boxShadow: theme.boxShadow.big,
}));

export const StyledMedia = styled(CardMedia)(() => ({
  minHeight: 280,
  aspectRatio: "1 / 1",
}));

export const FulfillmentCard = styled(Card)(({ theme }) => ({
  boxShadow: theme.boxShadow.tight,
  width: "100%",
}));

export const StyledAudio = styled("audio")(({ theme }) => ({
  verticalAlign: "middle",
  width: "100%",
  borderRadius: theme.shape.borderRadius,
}));

export const StyledBox = styled("div")(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: theme.palette.grey[100],
  border: `1px solid ${theme.palette.grey[300]}`,
  borderRadius: theme.shape.borderRadius,
  color: theme.palette.grey[500],
  padding: theme.spacing(1),
  userSelect: "none",
}));
