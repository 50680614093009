import PropTypes from "prop-types";
import * as React from "react";
import { Influencer, SignType } from "../../../../domain";
import { useCurrentUser } from "../../../../hooks";
import AuthModal from "../../../AuthModal";
import * as constants from "../../constants";
import Section from "../../Section";
import PromoTabs from "./PromoTabs";

/*
  sectionData is used to automate:
  1. lists of collections/shops/products displayed on the home page
  2. formatting domain data for the influencers object (src/hooks/useInfluencers.js)
    - map influencer model columns to influencer domain columns/methods
    - map product model columns to product domain columns/methods
  3. the order in which sections appear

  example section:
  {
    title: "Live & Upcoming",     // displayed above the cards
    modelName: "liveAndUpcoming", // corresponds to influencers[modelName] in: server/controllers/influencerCtrl/index.js/getInfluencers()
                                  //   model data is cached in redis and defined by sql query
    updateFavouriteStatus: true,  // handle card favorite selection, if not supplied:  false 
    type: 'influencer',           // influencer || product, if not supplied: influencer 
    look: constants.LOOK_TALL,    // values defined in src/components/Home/constants.js: LOOK_TALL, LOOK_PRODUCT, LOOK_WIDE
                                  //   if not supplied:  if type === product, default to LOOK_PRODUCT, else default LOOK_TALL 
  },
*/
const sectionData = [
  {
    name: "liveAndUpcoming",
    title: "Live & Upcoming",
    look: constants.LOOK_TALL,
    type: constants.INFLUENCER_TYPE,
    updateFavouriteStatus: true,
  },
  {
    name: "featured",
    title: "Featured",
    look: constants.LOOK_TALL,
    type: constants.INFLUENCER_TYPE,
    updateFavouriteStatus: true,
  },
  {
    name: "popularFranchises",
    title: "Popular Franchises",
    look: constants.LOOK_WIDE,
    type: constants.INFLUENCER_TYPE,
    updateFavouriteStatus: false,
  },
  {
    title: "Beloved Characters",
    name: "popularCharacters",
    look: constants.LOOK_TALL,
    type: constants.INFLUENCER_TYPE,
    updateFavouriteStatus: true,
  },
  {
    title: "Fan Favorites",
    name: "popularProducts",
    look: constants.LOOK_PRODUCT,
    type: constants.PRODUCT_TYPE,
    href: "/products",
    updateFavouriteStatus: false,
  },
  {
    title: "Pre-Signed Products",
    name: "presigned",
    look: constants.LOOK_PRODUCT,
    type: constants.PRODUCT_TYPE,
    href: `/products?${new URLSearchParams({
      st: `${SignType.presigned},${SignType.noAutograph}`,
    })}`,
    updateFavouriteStatus: false,
  },
  {
    title: "Signed with Personalized Video",
    name: "signAnytime",
    look: constants.LOOK_PRODUCT,
    type: constants.PRODUCT_TYPE,
    href: `/products?${new URLSearchParams({
      st: `${SignType.anytime}`,
    })}`,
    updateFavouriteStatus: false,
  },
  {
    title: "Even More",
    name: "evenMore",
    look: constants.LOOK_TALL,
    type: constants.INFLUENCER_TYPE,
    updateFavouriteStatus: true,
  },
];

export default function Main({ isMobile }) {
  const postLoginCbRef = React.useRef(false);
  const [onClose, setOnClose] = React.useState(null);

  const currentUser = useCurrentUser();

  const updateFavouriteStatus = React.useCallback(
    async (influencer, callback) => {
      const { isFavourite } = influencer;

      if (!currentUser?.userId && !postLoginCbRef.current) {
        return setOnClose(() => (user) => {
          const didLogin = Boolean(user);

          setOnClose(null);

          if (!didLogin) {
            return;
          }

          postLoginCbRef.current = true;
          updateFavouriteStatus(influencer, callback);
        });
      }

      try {
        if (influencer.isFavourite) {
          await Influencer.favourite({ influencer });
        } else {
          await Influencer.unfavourite({ influencer });
        }

        return callback(isFavourite);
      } catch (err) {
        console.error(err);
        return callback(!isFavourite);
      }
    },
    [currentUser?.userId],
  );

  return (
    <>
      <PromoTabs />

      {sectionData.map((section) => (
        <Section
          currentUser={currentUser}
          isMobile={isMobile}
          key={section.title}
          section={section}
          updateFavouriteStatus={updateFavouriteStatus}
        />
      ))}

      {Boolean(onClose) && <AuthModal onClose={onClose} isLoggingIn />}
    </>
  );
}

Main.propTypes = {
  isMobile: PropTypes.bool,
};
