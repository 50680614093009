import { createTheme, responsiveFontSizes } from "@material-ui/core/styles";
import {
  green,
  red,
  grey,
  yellow,
  orange,
  purple,
} from "@material-ui/core/colors";

const interFontStack = [
  "Inter",
  "-apple-system",
  "BlinkMacSystemFont",
  '"Segoe UI"',
  "Roboto",
  '"Helvetica Neue"',
  "Arial",
  "sans-serif",
].join(",");

const customerPalette = {
  buttonGradientDefault:
    "linear-gradient(93.21deg, #93CFF1 1.22%, #2A6FC0 88.2%)",
  buttonGradientHover:
    "linear-gradient(268.62deg, #2A6FC0 1.2%, #5899D6 99.36%), #C4C4C4",
  buttonGradientActive:
    "linear-gradient(0deg, #2A6FC0, #2A6FC0), linear-gradient(0deg, #358FAB, #358FAB), #C4C4C4",
  buttonDisabled: "#DDDDDD",
  buttonShadowDefault: "1px 1px 5px rgba(0, 0, 0, 0.1);",
  buttonShadowHover: "1px 1px 5px rgba(0, 0, 0, 0.1);",
  checkboxGradientDefault:
    "linear-gradient(86.12deg, #385A6D 12.48%, #34B6FF 73.95%), #DDDDDD",
  primaryDark: "#2A6FC0",
  primaryMedium: "#5899D6",
  primaryLight: "#93CFF1",
  primaryExtraLight: "#C7E3F3",
};

const talentPalette = {
  buttonGradientDefault:
    "linear-gradient(268.66deg, #358FAB -7.68%, #A8DCEC 99.38%), #C4C4C4",
  buttonGradientHover:
    "linear-gradient(268.66deg, #358FAB -7.68%, #75BACF 99.38%), #C4C4C4",
  buttonGradientActive: "linear-gradient(0deg, #358FAB, #358FAB), #C4C4C4",
  buttonDisabled: "#DDDDDD",
  buttonShadowDefault: "1px 1px 5px rgba(0, 0, 0, 0.1);",
  buttonShadowHover: "1px 1px 5px rgba(0, 0, 0, 0.1);",
  checkboxGradientDefault:
    "linear-gradient(268.66deg, #358FAB -7.68%, #A8DCEC 99.38%), linear-gradient(86.12deg, #385A6D 12.48%, #34B6FF 73.95%), #DDDDDD",
  primaryDark: "#358FAB",
  primaryMedium: "#75BACF",
  primaryLight: "#A8DCEC",
  primaryExtraLight: "#D5ECF3",
};

const basePalette = {
  greyDefault: "#2b2b2b",
  greyMidDark: "#3B3B3B",
  greyLighter: "#8E8E8E",
  greyLight: "#4B4B4B",
  greyMid: "#5b5b5b",
  backgroundDefault: "#F7F9FC",
  buttonDisabled: "#DDDDDD",
  redMain: "#FF3333",
  redDark: "#AD0404",
};

const theme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: customerPalette.primaryDark,
        medium: customerPalette.primaryMedium,
        light: customerPalette.primaryLight,
        extraLight: customerPalette.primaryExtraLight,
        background: basePalette.backgroundDefault,
      },
      secondary: {
        main: talentPalette.primaryDark,
        medium: talentPalette.primaryMedium,
        light: talentPalette.primaryLight,
        extraLight: talentPalette.primaryExtraLight,
        background: basePalette.backgroundDefault,
      },
      green: {
        limeLight: "#D4FFCD",
        limeDark: "#18AA00",
        main: green[600],
        light: green[400],
        dark: green[800],
      },
      red: {
        main: basePalette.redMain,
        light: red[400],
        dark: basePalette.redDark,
      },
      yellow: {
        main: yellow[600],
        light: yellow[400],
        dark: yellow[800],
      },
      grey: {
        main: basePalette.greyDefault,
        dark: grey[900],
        midDark: basePalette.greyMidDark,
        light: basePalette.greyLight,
        lighter: basePalette.greyLighter,
        mid: basePalette.greyMid,
        disabled: basePalette.buttonDisabled,
      },
      blue: {
        main: "#006EAF",
      },
      light: {
        main: "#F9F9F9",
      },
      background: {
        main: "#F7F9FC",
        light: "#fff",
      },
      orange: {
        ...orange,
      },
      purple: {
        ...purple,
      },
      text: {
        primary: basePalette.greyDefault,
        secondary: basePalette.greyLight,
      },
    },
    shape: {
      borderRadius: 5,
    },
    gradient: {
      primary: customerPalette.buttonGradientDefault,
      primaryDark: customerPalette.buttonGradientHover,
      secondary: talentPalette.buttonGradientDefault,
      secondaryDark: talentPalette.buttonGradientHover,
      checkboxGradientCustomer: customerPalette.checkboxGradientDefault,
      checkboxGradientTalent: talentPalette.checkboxGradientDefault,
      grey: "linear-gradient(283.7deg, #2B2B2B 10.95%, #4D4D4D 89.36%), rgba(136, 136, 136, 0.75);",
      pink: "linear-gradient(104.14deg, #C0E5FF 9.51%, rgba(214, 245, 255, 0.6) 50.36%, #EDD1EE 92.53%);",
      button: `linear-gradient(93.21deg, #93cff1 1.22%, #2a6fc0 88.2%),
         linear-gradient(98.52deg, #9de3e8 0%, #9886be 100%),
         linear-gradient(98.52deg, #87e2ff 0%, #feb9ff 100%), #2b2b2b`,
      text: `linear-gradient(93.21deg, #E15499 1.22%, #2A6FC0 88.2%), #2B2B2B`,
      textReverse: `linear-gradient(93.21deg, #2A6FC0 1.22%,  #E15499 88.2%), #2B2B2B`,
      gold: "linear-gradient(90deg, #FFE68E 0%, #C2A049 100%), linear-gradient(90deg, #FFC48E 0%, #B491FF 100%), #FFFFFF;",
      blue: "linear-gradient(86.12deg, #385A6D 12.48%, #34B6FF 73.95%), #CCCCCC;",
      lightBlue: "linear-gradient(0deg, #00AFE6, #00AFE6), #FFFFFF;",
      lightBlueHover: "linear-gradient(0deg, #0095C4, #0095C4), #FFFFFF;",
    },
    boxShadow: {
      buttonDefault: customerPalette.buttonShadowDefault,
      buttonHover: customerPalette.buttonShadowHover,
      softCard: "0px 2px 10px rgba(0, 0, 0, 0.1);",
      soft: "0 1px 12px 4px rgba(0, 0, 50, 0.05)",
      light: "0px 0px 10.2765px rgba(0, 0, 0, 0.1)",
      medium: "0px 0px 18px rgba(0, 0, 0, 0.1)",
      mediumLight: "0px 0px 16px rgba(0, 0, 0, 0.1)",
      mediumDark: "2px 2px 4px rgba(0, 0, 0, 0.25)",
      faint: "2px 2px 10px 0px #0000001A",
      sharp: "0 2px 8px rgba(0, 0, 0, 0.25)",
      offsetDark: "4px 4px 15px rgba(0, 0, 0, 0.25)",
      tight: "1.25px 1.25px 6.25px rgba(0, 0, 0, 0.1)",
      big: "0px 27px 141px rgba(0, 0, 0, 0.07), 0px 9.85544px 51.4673px rgba(0, 0, 0, 0.0374541), 0px 4.78464px 24.9864px rgba(0, 0, 0, 0.0288311), 0px 2.34552px 12.2488px rgba(0, 0, 0, 0.0236566);",
    },
    typography: {
      fontFamily: interFontStack,
      body1: {
        fontFamily: interFontStack,
        color: basePalette.greyDefault,
      },
      body2: {
        fontFamily: interFontStack,
        color: basePalette.greyDefault,
      },
      h1: {
        fontFamily: interFontStack,
        fontWeight: 700,
        color: basePalette.greyDefault,
      },
      h2: {
        fontFamily: interFontStack,
        fontWeight: 600,
        color: basePalette.greyDefault,
      },
      h3: {
        fontFamily: interFontStack,
        fontWeight: 600,
        color: basePalette.greyDefault,
      },
      h4: {
        fontFamily: interFontStack,
        fontWeight: 700,
        color: basePalette.greyDefault,
      },
      h5: {
        fontFamily: interFontStack,
        fontWeight: 700,
        color: basePalette.greyDefault,
      },
      h6: {
        fontFamily: interFontStack,
        fontWeight: 600,
        color: basePalette.greyDefault,
      },
      button: {
        fontFamily: interFontStack,
        fontWeight: 700,
        textTransform: "none",
      },
      caption: {
        fontFamily: interFontStack,
        color: basePalette.greyDefault,
      },
      subtitle1: {
        fontFamily: interFontStack,
        fontWeight: 500,
        color: basePalette.greyLight,
        lineHeight: "1.5rem",
      },
      subtitle2: {
        fontFamily: interFontStack,
        fontWeight: 500,
        color: basePalette.greyDefault,
        lineHeight: "1.5rem",
      },
      smallBoldText: {
        fontFamily: interFontStack,
        fontWeight: 600,
        fontSize: "0.8rem",
      },
      label: {
        fontFamily: interFontStack,
      },
      light: 300,
      normal: 400,
      medium: 500,
      semiBold: 600,
      bold: 700,
      interFontStack,
    },
    props: {
      MuiButtonBase: {
        disableRipple: true,
      },
    },
    overrides: {
      MuiOutlinedInput: {
        input: {
          background: "white",
          borderRadius: 5,
          "&:filled": {},
        },
        notchedOutline: {
          borderColor: grey[400],
          borderRadius: 5,
        },
        root: {
          background: "white",
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: grey[600],
          },
        },
      },
      MuiIconButton: {
        root: {
          "&:focus": {
            outline: `2px solid ${basePalette.greyDefault}`,
          },
        },
      },
      MuiCheckbox: {
        root: {
          color: basePalette.greyDefault,
        },
      },
      MuiButton: {
        text: {
          color: basePalette.greyDefault,
          fontWeight: 600,
        },
        textPrimary: {
          color: customerPalette.primaryDark,
        },
        textSecondary: {
          color: talentPalette.primaryDark,
        },
        textSizeSmall: {
          fontSize: "0.75rem",
        },
        outlined: {
          "&:focus": {
            boxShadow: customerPalette.buttonShadowDefault,
            outline: `2px solid ${basePalette.greyDefault}`,
          },
        },
        contained: {
          boxShadow: customerPalette.buttonShadowDefault,
          color: basePalette.greyLight,
          borderRadius: 5,
          padding: "8px 16px",
          outline: `1px solid ${basePalette.greyLight}`,
          outlineOffset: -1,
          fontWeight: 600,
          transition:
            "box-shadow 0.2s ease-out, background-color 0.2s ease-out",
          "&:focus": {
            boxShadow: customerPalette.buttonShadowDefault,
            outline: "2px solid black",
          },
          "&:hover": {
            boxShadow: customerPalette.buttonShadowHover,
          },
          "&:active": {
            boxShadow: "none",
          },
          "&:disabled": {
            background: customerPalette.buttonDisabled,
          },
          "&.blue": {
            background: "linear-gradient(0deg, #00AFE6, #00AFE6), #FFFFFF;",
            transition: "all 0.2s ease-out",
            color: "white",
          },
          "&.blue:hover": {
            background: "linear-gradient(0deg, #00A6DB, #00A6DB), #FFFFFF",
          },
        },
        containedPrimary: {
          backgroundColor: "unset",
          background: "none",
          boxShadow: customerPalette.buttonShadowDefault,
          color: "white",
          borderRadius: 5,
          padding: "8px 16px",
          outline: "initial",
          transition: "box-shadow 0.2s ease-out",
          position: "relative",
          zIndex: 0,
          "&:hover": {
            boxShadow: customerPalette.buttonShadowHover,
            transition: "box-shadow 0.2s ease-out",
          },
          "&:focus": {
            outline: "2px solid black",
          },
          "&:active": {
            boxShadow: "none",
            transition: "box-shadow 0.2s ease-out",
            background: customerPalette.buttonGradientActive,
          },
          "&:disabled": {
            boxShadow: "none",
            transition: "box-shadow 0.2s ease-out",
            background: customerPalette.buttonDisabled,
          },
          "&:before": {
            content: "''",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 5,
            position: "absolute",
            background: customerPalette.buttonGradientDefault,
            opacity: 1,
            zIndex: -1,
          },
          "&:after": {
            content: "''",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 5,
            position: "absolute",
            background: customerPalette.buttonGradientHover,
            transition: "opacity 0.2s ease-out",
            opacity: 0,
            zIndex: -1,
          },
          "&:hover:after": {
            background: customerPalette.buttonGradientHover,
            opacity: 1,
            transition: "opacity 0.2s ease-out",
          },
          "&:disabled:before, &:disabled:after": {
            content: "unset",
          },
        },
        containedSecondary: {
          background: "none",
          backgroundColor: "unset",
          boxShadow: talentPalette.buttonShadowDefault,
          color: "white",
          borderRadius: 5,
          padding: "8px 16px",
          transition: "box-shadow 0.2s ease-out",
          outline: "initial",
          position: "relative",
          zIndex: 0,
          "&:hover": {
            boxShadow: talentPalette.buttonShadowHover,
            transition: "box-shadow 0.2s ease-out",
          },
          "&:focus": {
            outline: "2px solid black",
          },
          "&:active": {
            boxShadow: "none",
            transition: "box-shadow 0.2s ease-out",
            background: talentPalette.buttonGradientActive,
          },
          "&:disabled": {
            boxShadow: "none",
            transition: "box-shadow 0.2s ease-out",
            background: talentPalette.buttonDisabled,
          },
          "&:disabled:before, &:disabled:after": {
            content: "unset",
          },
          "&:before": {
            content: "''",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 5,
            position: "absolute",
            background: talentPalette.buttonGradientDefault,
            opacity: 1,
            zIndex: -1,
          },
          "&:after": {
            content: "''",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 5,
            position: "absolute",
            background: talentPalette.buttonGradientHover,
            transition: "opacity 0.2s ease-out",
            opacity: 0,
            zIndex: -1,
          },
          "&:hover:after": {
            opacity: 1,
            transition: "opacity 0.2s ease-out",
          },
        },
        containedSizeLarge: {
          padding: "12px 36px",
          minHeight: 40,
        },
        containedSizeSmall: {
          padding: "6px 16px",
          minHeight: 22,
        },
      },
      MuiSwitch: {
        root: {
          width: 36,
          height: 20,
          padding: 0,
          boxSizing: "border-box",
        },
        switchBase: {
          boxSizing: "border-box",
          padding: 3,
          "&$checked": {
            color: "white",
            transform: "translateX(16px)",
            "& + $track": {
              background: basePalette.greyLight,
              backgroundColor: basePalette.greyLight,
              opacity: 1,
              border: "none",
            },
          },
        },
        thumb: {
          width: 14,
          height: 14,
          boxSizing: "border-box",
          border: "none",
          boxShadow: "none",
        },
        track: {
          boxSizing: "border-box",
          borderRadius: 16,
          border: "none",
          backgroundColor: basePalette.buttonDisabled,
          opacity: 1,
          transition: "background-color 0.2s ease-out",
        },
        colorPrimary: {
          "&$checked": {
            color: "white",
            "& + $track": {
              background: customerPalette.checkboxGradientDefault,
            },
          },
          "& + $track": {
            background: basePalette.buttonDisabled,
          },
        },
        colorSecondary: {
          "&$checked": {
            color: "white",
            "& + $track": {
              background: talentPalette.checkboxGradientDefault,
            },
          },
          "& + $track": {
            background: basePalette.buttonDisabled,
          },
        },
      },
    },
  }),
);

export default theme;
