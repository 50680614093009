import { Button } from "@material-ui/core";
import { Stars as StarsIcon } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import useSWR from "swr";
import { Product } from "../../domain";
import { fetcher, useCurrentUser } from "../../hooks";
import AuthModal from "../AuthModal";

function useFavouriteStatus(productId) {
  const { data, error, mutate } = useSWR(
    productId === null ? null : `/api/favourites/product/${productId}`,
    fetcher,
  );

  return {
    error,
    isError: Boolean(error),
    isFavourite: data?.isFavourite,
    isLoading: !data && !error,
    refresh: mutate,
  };
}

export default function ProductWishlistButton({ product, ...props }) {
  const currentUser = useCurrentUser();
  const { isFavourite, refresh } = useFavouriteStatus(
    currentUser.email ? product.productId : null,
  );
  const [showAuthModal, setShowAuthModal] = React.useState(false);

  const onClick = async () => {
    if (!currentUser.email) {
      setShowAuthModal(true);
      return;
    }

    try {
      if (isFavourite) {
        await product.unfavourite();
      } else {
        await product.favourite();
      }
      await refresh();
    } catch (err) {
      console.error(err);
    }
  };

  const handleCloseAuthModal = async (user) => {
    setShowAuthModal(false);

    if (!user) {
      return;
    }

    try {
      await product.favourite();
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Button
        startIcon={!isFavourite ? <StarsIcon /> : undefined}
        {...props}
        onClick={onClick}
      >
        {isFavourite ? "Remove from wishlist" : "Add to wishlist"}
      </Button>

      {showAuthModal && <AuthModal onClose={handleCloseAuthModal} />}
    </>
  );
}

ProductWishlistButton.propTypes = {
  product: PropTypes.instanceOf(Product).isRequired,
};
