import PropTypes from "prop-types";
import { Box, IconButton } from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import { S, D } from "@mobily/ts-belt";
import * as React from "react";
import { Form } from "../../theme";
import { useDebounce } from "../../hooks";

export default function Search({ setFilter, matchesSmDown }) {
  const [query, setQuery] = React.useState("");

  const setFilterAndRestoreRank = React.useCallback(
    (fn) => setFilter((filter) => D.merge(fn(filter), { rank: 0 })),
    [setFilter],
  );

  useDebounce(
    () => {
      const name = S.trim(query);

      if (!name) {
        setFilterAndRestoreRank(D.merge({ name: "" }));
      } else if (name.length >= 3) {
        setFilterAndRestoreRank(D.merge({ name }));
      }
    },
    320,
    [query],
  );

  return (
    <Box
      flex="1"
      position="relative"
      width={matchesSmDown ? "100%" : undefined}
      maxWidth="32rem"
      minWidth="25rem"
    >
      <Form.Input
        fullWidth
        label="Search talent, characters, actors, products and more..."
        size="small"
        variant="outlined"
        onChange={(evt) => {
          setQuery(evt.target.value);
        }}
        value={query}
      />

      {Boolean(query) && (
        <Box
          alignItems="center"
          bottom={0}
          display="flex"
          position="absolute"
          right={8}
          top={0}
        >
          <IconButton
            size="small"
            onClick={() => {
              setQuery("");
            }}
          >
            <Clear fontSize="small" />
          </IconButton>
        </Box>
      )}
    </Box>
  );
}

Search.propTypes = {
  matchesSmDown: PropTypes.bool,
  filter: PropTypes.object.isRequired,
  setFilter: PropTypes.func.isRequired,
};
