import PropTypes from "prop-types";
import { D } from "@mobily/ts-belt";
import * as React from "react";
import { Form } from "../../theme";
import { useFilters } from "./utils";

const getName = D.get("name");
const getId = D.get("id");

export default function Order({ filter, setFilter }) {
  const { filters } = useFilters();

  return (
    <Form.SimpleSelect
      formControlProps={{ fullWidth: true }}
      value={filter.order}
      options={filters.order}
      getOptionLabel={getName}
      getOptionValue={getId}
      onChange={(evt) => {
        setFilter(D.merge({ rank: 0, order: evt.target.value }));
      }}
    />
  );
}

Order.propTypes = {
  filter: PropTypes.shape({
    order: PropTypes.string.isRequired,
  }),
  setFilter: PropTypes.func.isRequired,
};
