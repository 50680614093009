import * as React from "react";
import useSWR from "swr";
import useSWRMutation from "swr/mutation";
import { Influencer, Product, withIncludes } from "../domain";
import fetcher from "./fetcher";

const baseEndpoint = "/api/favourites";

export default function useFavourite(influencerId) {
  const { data, error, isValidating } = useSWR(
    influencerId ? `${baseEndpoint}/${influencerId}` : null,
    fetcher,
  );

  return {
    data,
    isLoading: !data && !error,
    isError: Boolean(error),
    isValidating,
    error,
  };
}

async function toggleFavourite(url) {
  return fetcher(url, { method: "PUT" });
}

export function useFavouriteMutation(influencerId) {
  const { trigger } = useSWRMutation(
    `${baseEndpoint}/${influencerId}`,
    toggleFavourite,
  );

  return { trigger };
}

export function useMyFavourites() {
  const { data, error, isValidating, mutate } = useSWR(
    `${baseEndpoint}/myFavourites/`,
    fetcher,
  );

  const influencers = React.useMemo(() => {
    const influencers = {
      collections: [],
      talent: [],
    };

    if (Array.isArray(data?.shops)) {
      data?.shops.forEach((row) => {
        const influencer = Influencer.from(row);

        if (influencer.isGroupShop) {
          influencers.collections.push(influencer);
        } else {
          influencers.talent.push(influencer);
        }
      });
    }

    return influencers;
  }, [data]);

  return {
    influencers,
    refresh: mutate,
    isLoading: !data && !error,
    isError: Boolean(error),
    isValidating,
    error,
  };
}

export function useWishlist() {
  const { data, error, isValidating, mutate } = useSWR(
    `${baseEndpoint}/wishlist/`,
    fetcher,
  );

  const products = React.useMemo(() => {
    if (!Array.isArray(data?.products)) {
      return [];
    }

    return data.products.map((product) => withIncludes(Product.from(product)));
  }, [data]);

  return {
    products,
    refresh: mutate,
    isLoading: !data && !error,
    isError: Boolean(error),
    isValidating,
    error,
  };
}
