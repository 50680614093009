import PropTypes from "prop-types";
import { atom, useAtom } from "jotai";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import * as React from "react";
import { useDebounce } from "../../hooks";
import { Form } from "../../theme";
import { realisticConfetti } from "../../util/confetti";

const STATUS = {
  AVAILABLE: "available",
  ERROR: "error",
  EXISTS: "exists",
  EXPIRED: "expired",
  LOADING: "loading",
  PENDING: "pending",
  RESENT: "resent",
  SUCCESS: "success",
  UNKNOWN: "unknown",
};

function buttonStateFor(status) {
  switch (status) {
    case STATUS.EXPIRED:
    case STATUS.PENDING:
      return { text: "Resend Invite", disabled: false };
    case STATUS.AVAILABLE:
      return { text: "Send Invite", disabled: false };
    case STATUS.EXISTS:
      return { text: "User Exists", disabled: true };
    case STATUS.LOADING:
      return { text: "Loading", disabled: true };
    default:
      return { text: "Send Invite", disabled: true };
  }
}

async function getStatusFor(email) {
  const response = await window.fetch("/api/userInvite/status", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email }),
  });

  const json = await response.json();

  if (response.ok) {
    return json.status ?? STATUS.UNKNOWN;
  }

  if (response.status === 422) {
    const err = new Error("Invalid email");
    err.status = STATUS.ERROR;
    throw err;
  }

  const err = new Error(json.message);
  err.status = json.status ?? STATUS.ERROR;
  throw err;
}

async function createInviteFor(user) {
  const response = await window.fetch("/api/userInvite/invite", {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  });

  const json = await response.json();

  if (!response.ok) {
    const err = new Error(json.message);
    err.status = json.status ?? STATUS.ERROR;
    throw err;
  }

  json.status = response.status === 200 ? STATUS.SUCCESS : STATUS.RESENT;

  return json;
}

function UserInviteModalContent({ onClose, influencerId, defaultEmail }) {
  const lastEmailCheckRef = React.useRef("");
  const [email, setEmail] = React.useState(defaultEmail ?? "");
  const [status, setStatus] = React.useState(STATUS.UNKNOWN);
  const [isTalent, setIsTalent] = React.useState(false);
  const [isAgent, setIsAgent] = React.useState(false);
  const [isWhiteLabel, setIsWhiteLabel] = React.useState(false);
  const [error, setError] = React.useState("");
  const [loginLink, setLoginLink] = React.useState("");

  const onChange = () => {
    if (status === STATUS.SUCCESS || status === STATUS.RESENT) {
      return;
    }

    if (error) {
      setStatus(STATUS.UNKNOWN);
      setError("");
    }
  };

  const getStatus = React.useCallback(async (rawEmail) => {
    const email = rawEmail.trim();
    setEmail(email);

    if (email === lastEmailCheckRef.current) {
      return;
    }

    lastEmailCheckRef.current = email;

    if (!email || email.length < 3 || !email.includes("@")) {
      setStatus(STATUS.UNKNOWN);
      setError("");
      return;
    }

    try {
      setStatus(STATUS.LOADING);
      const status = await getStatusFor(email);
      setStatus(status);
      setError("");
    } catch (err) {
      setStatus(err.status ?? STATUS.ERROR);
      setError(err.message);
    }
  }, []);

  const handleBlur = (evt) => {
    getStatus(evt.target.value);
  };

  useDebounce(
    () => {
      if (status === STATUS.SUCCESS || status === STATUS.RESENT) {
        return;
      }

      getStatus(email);
    },
    500,
    [email],
  );

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const form = evt.target;
    const formData = new FormData(form);
    const email = formData.get("email")?.trim() ?? "";
    const firstName = formData.get("firstName")?.trim() ?? "";
    const lastName = formData.get("lastName")?.trim() ?? "";
    const phoneNumber = formData.get("phoneNumber")?.trim() ?? "";

    const user = {
      email,
      firstName,
      influencerId,
      isAgent,
      isTalent,
      isWhiteLabel,
      lastName,
      phoneNumber,
    };

    try {
      const result = await createInviteFor(user);

      form.reset();
      setEmail("");
      setIsTalent(false);
      setIsAgent(false);
      setIsWhiteLabel(false);
      setError("");
      setStatus(result.status ?? STATUS.SUCCESS);
      setLoginLink(result.user?.loginLink ?? "");
      realisticConfetti();
    } catch (err) {
      setStatus(STATUS.ERROR);
      setError(err.message);
    }
  };

  const { text: buttonText, disabled: shouldDisable } = buttonStateFor(status);

  return (
    <form onSubmit={handleSubmit}>
      <Box display="flex" gridGap="16px" width="100%">
        <Form.Input
          error={Boolean(error)}
          fullWidth
          helperText={error}
          inputProps={{ maxLength: 100 }}
          label="Email"
          name="email"
          onBlur={handleBlur}
          onChange={(evt) => setEmail(evt.target.value)}
          placeholder="Email"
          required
          size="small"
          type="email"
          value={email}
          variant="outlined"
        />
      </Box>

      <Box display="flex" gridGap="16px" my={2}>
        <Form.Input
          disabled={status !== STATUS.AVAILABLE}
          inputProps={{ maxLength: 50 }}
          label="First Name"
          name="firstName"
          onChange={onChange}
          size="small"
          type="text"
          variant="outlined"
          fullWidth
        />

        <Form.Input
          disabled={status !== STATUS.AVAILABLE}
          inputProps={{ maxLength: 50 }}
          label="Last Name"
          name="lastName"
          onChange={onChange}
          size="small"
          type="text"
          variant="outlined"
          fullWidth
        />
      </Box>

      <Box display="flex" gridGap="16px" my={2}>
        <Box display="flex" gridGap="16px">
          <Form.Input
            disabled={status !== STATUS.AVAILABLE}
            inputProps={{
              inputMode: "numeric",
              maxLength: 30,
            }}
            label="Phone Number"
            name="phoneNumber"
            onChange={onChange}
            size="small"
            type="text"
            variant="outlined"
          />

          <Form.SmallCheckbox
            checked={isTalent}
            disabled={status !== STATUS.AVAILABLE || isWhiteLabel}
            label="Talent"
            onChange={() => {
              setIsTalent((prev) => !prev);
              setIsAgent(false);
              onChange();
            }}
          />

          <Form.SmallCheckbox
            checked={isAgent}
            disabled={status !== STATUS.AVAILABLE || isWhiteLabel}
            label="Agent"
            onChange={() => {
              setIsAgent((prev) => !prev);
              setIsTalent(false);
              onChange();
            }}
          />

          <Form.SmallCheckbox
            checked={isWhiteLabel}
            disabled={status !== STATUS.AVAILABLE}
            label="White Label"
            onChange={() => {
              setIsWhiteLabel((prev) => {
                if (prev) {
                  setIsTalent(false);
                  setIsAgent(false);
                } else {
                  setIsTalent(true);
                  setIsAgent(false);
                }

                return !prev;
              });

              onChange();
            }}
          />
        </Box>
      </Box>

      <Box display="flex" flexDirection="column">
        {influencerId && (
          <Typography gutterBottom variant="caption">
            This user will be added to the access list of this shop.
            {isTalent &&
              " If the shop has no shop owner, then this user will be set as the shop owner."}
          </Typography>
        )}

        {!influencerId && (
          <Typography variant="caption" color="textSecondary">
            Setting the user as an agent or talent is optional and won&apos;t
            create a shop. To create a shop for them, use &ldquo;convert to
            talent&rdquo; or &ldquo;convert to agent&rdquo; from the User Search
            page after the user is created.
          </Typography>
        )}
      </Box>

      {status !== STATUS.SUCCESS && status !== STATUS.RESENT && (
        <Box
          alignItems="flex-end"
          display="flex"
          gridGap="8px"
          justifyContent="flex-end"
          my={2}
        >
          <Box minWidth="120px">
            <Button
              fullWidth
              size="small"
              variant="contained"
              color="secondary"
              type="submit"
              disabled={shouldDisable || status === STATUS.LOADING}
            >
              {buttonText}
            </Button>
          </Box>
        </Box>
      )}

      {(status === STATUS.SUCCESS || status === STATUS.RESENT) && (
        <Box
          display="flex"
          flexDirection="column"
          gridGap="16px"
          alignItems="flex-end"
          my={2}
        >
          <Typography variant="body2">
            Invite sent successfully!{" "}
            {loginLink && (
              <span>
                If the user does not get their email, you can also share this
                login link with them:
                <br />
                <code style={{ wordBreak: "break-all" }}>{loginLink}</code>
              </span>
            )}
          </Typography>

          <Box display="flex" gridGap="16px">
            <Button
              color="secondary"
              onClick={() => {
                setStatus(STATUS.UNKNOWN);
                setLoginLink("");
              }}
              size="small"
              variant="contained"
            >
              Invite Again
            </Button>

            <Button
              color="primary"
              onClick={onClose}
              size="small"
              variant="contained"
            >
              Done
            </Button>
          </Box>
        </Box>
      )}
    </form>
  );
}

UserInviteModalContent.propTypes = {
  onClose: PropTypes.func.isRequired,
  influencerId: PropTypes.number,
  defaultEmail: PropTypes.string,
};

const defaultStateAtom = atom(false);

export default function UserInvite({
  stateAtom = defaultStateAtom,
  size = "medium",
  influencerId,
}) {
  const [open, setOpen] = useAtom(stateAtom);

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size={size}
        onClick={() => setOpen(true)}
      >
        Invite User
      </Button>

      <Dialog
        open={Boolean(open)}
        onClose={() => setOpen(false)}
        keepMounted={false}
      >
        <DialogTitle>Invite User</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Invite a new user to the platform by entering their email address.
            Please fill out additional information if possible.{" "}
          </DialogContentText>

          <UserInviteModalContent
            influencerId={influencerId}
            onClose={() => {
              setOpen(false);
            }}
            defaultEmail={open?.email}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}

UserInvite.propTypes = {
  stateAtom: PropTypes.object,
  size: PropTypes.oneOf(["small", "medium", "large"]),
  influencerId: PropTypes.number,
};
