import { Box, CardContent, IconButton, Typography } from "@material-ui/core";
import { Save } from "@material-ui/icons";
import PropTypes from "prop-types";
import * as React from "react";
import { SmallAvatar } from "../../theme";
import * as styles from "./styles";

export default function Card({ order }) {
  return (
    <styles.StyledCard>
      <styles.StyledMedia image={order.image_url} title={order.product_name} />

      <CardContent>
        <Typography gutterBottom variant="h4" component="h2">
          {order.product_name}
        </Typography>

        <Typography
          gutterBottom
          variant="body2"
          color="textSecondary"
          component="p"
        >
          Order Number: {order.order_number}
        </Typography>

        <Typography
          color="textSecondary"
          component="p"
          gutterBottom
          variant="body2"
        >
          Personalized To: {order.personalization_to}
        </Typography>

        <Box
          mt={2}
          alignItems="center"
          display="flex"
          flexDirection="column"
          gridGap="16px"
        >
          {order.fulfillers.map((fulfiller) => (
            <styles.FulfillmentCard key={fulfiller.id}>
              <CardContent>
                <Box mb={1} display="flex" gridGap="8px" alignItems="center">
                  <SmallAvatar
                    src={fulfiller.avatar_url}
                    alt={fulfiller.influencer_name}
                  />

                  <Typography variant="h6" component="h3">
                    {fulfiller.influencer_name}
                  </Typography>
                </Box>

                <Box display="flex" gridGap="16px" alignItems="center">
                  <Box flex="1">
                    {Boolean(fulfiller.audio_shoutout_url) && (
                      <styles.StyledAudio
                        controls
                        src={fulfiller.audio_shoutout_url}
                      >
                        Your browser does not support the audio element.
                      </styles.StyledAudio>
                    )}

                    {!fulfiller.audio_shoutout_url && (
                      <styles.StyledBox>
                        Audio shoutout not yet available.
                      </styles.StyledBox>
                    )}
                  </Box>

                  <IconButton
                    component="a"
                    disabled={!fulfiller.audio_shoutout_url}
                    href={fulfiller.audio_shoutout_url}
                  >
                    <Save />
                  </IconButton>
                </Box>
              </CardContent>
            </styles.FulfillmentCard>
          ))}
        </Box>
      </CardContent>
    </styles.StyledCard>
  );
}

Card.propTypes = {
  order: PropTypes.shape({
    image_url: PropTypes.string,
    order_number: PropTypes.string,
    personalization_to: PropTypes.string,
    product_name: PropTypes.string,
    product_subtext: PropTypes.string,
    fulfillers: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        avatar_url: PropTypes.string,
        influencer_name: PropTypes.string,
        audio_shoutout_url: PropTypes.string,
      }),
    ),
  }),
};
