import { SignType } from "../../domain";

const stepsRegistry = new Map();
const defaultKey = "default";

const Steps = {
  for(autographPurchase) {
    const { signedType } = autographPurchase;

    const steps = stepsRegistry.get(signedType);

    if (steps) {
      return steps(autographPurchase);
    }

    const defaultSteps = stepsRegistry.get(defaultKey);

    if (defaultSteps) {
      return defaultSteps(autographPurchase);
    }

    throw new Error(`No steps found for signedType "${signedType}"`);
  },
};

function shipmentRequired({ received, shipped, signed, fulfillers }) {
  const steps = [
    {
      step: 0,
      label: "Awaiting Signatures",
      description:
        "Your order is in line to get signed by the Talent. If there are multiple signers, all signatures are required before your order can be shipped to you.",
    },

    {
      step: 1,
      label: "Heading to Warehouse",
      description:
        "Your order has been signed! Now it's on its way back to our warehouse for quality assurance and processing.",
    },

    {
      step: 2,
      label: "Preparing for Shipment",
      description:
        "We have verified your order and it is almost ready to ship.",
    },

    {
      step: 3,
      label: "Shipped",
      description:
        "Your order has been shipped. Please allow a week or two for it to arrive.",
    },
  ];

  return {
    name: "shipment-required",
    steps,
    getStatus() {
      if (!signed || !fulfillers.every((fulfiller) => fulfiller.fulfilled)) {
        return steps[0];
      }

      if (!received) {
        return steps[1];
      }

      if (!shipped) {
        return steps[2];
      }

      return steps[3];
    },
    signerTextFor(fulfiller) {
      if (fulfiller?.fulfilled) {
        return "Signed";
      }

      return "Awaiting Signature";
    },
  };
}

stepsRegistry.set(SignType.Livestream, shipmentRequired);
stepsRegistry.set(defaultKey, shipmentRequired);

function quickShip({ shipped }) {
  const steps = [
    {
      step: 0,
      label: "Preparing for Shipment",
      description:
        "We have verified your order and it is almost ready to ship.",
    },

    {
      step: 1,
      label: "Shipped",
      description:
        "Your order has been shipped. Please allow a week or two for it to arrive.",
    },
  ];

  return {
    name: "quickship",
    steps,
    getStatus() {
      if (shipped) {
        return steps[1];
      }

      return steps[0];
    },
    signerTextFor() {
      return "";
    },
  };
}

stepsRegistry.set(SignType.presigned, quickShip);
stepsRegistry.set(SignType.noAutograph, quickShip);

function noShipment({ signed, fulfillers }) {
  const steps = [
    {
      step: 0,
      label: "Awaiting Completion",
      description: "Your order is in line to be completed by the Talent.",
    },
    {
      step: 1,
      label: "Completed",
      description: "Your order has been completed.",
    },
  ];

  return {
    name: "no-shipment",
    steps,
    getStatus() {
      if (signed || fulfillers.every((fulfiller) => fulfiller.fulfilled)) {
        return steps[1];
      }

      return steps[0];
    },
    signerTextFor(fulfiller) {
      if (fulfiller?.fulfilled) {
        return "Completed";
      }

      return "Awaiting Completion";
    },
  };
}

stepsRegistry.set(SignType.shoutout, noShipment);
stepsRegistry.set(SignType.inPerson, noShipment);
stepsRegistry.set(SignType.audioShoutout, noShipment);

export default Steps;
