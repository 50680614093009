import { O, S, A, pipe } from "@mobily/ts-belt";

export default async function downloadFile(res, filename) {
  const blob = await res.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;

  if (!filename && res.headers.has("content-disposition")) {
    // eslint-disable-next-line no-param-reassign
    filename = pipe(
      res.headers.get("Content-Disposition"),
      S.replaceByRe(/["']+/gi, ""),
      S.split("="),
      A.get(1),
    );
  }

  if (O.isNone(filename)) {
    throw new Error("Could not get filename from response");
  }

  a.download = filename;
  a.click();

  window.URL.revokeObjectURL(url);
}

export function downloadCSVFromText(fileText, fileName = "report.csv") {
  const file = new File([fileText], fileName, { type: "text/csv" });
  const url = window.URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;

  a.download = file.name;
  a.click();

  window.URL.revokeObjectURL(url);
}
