import {
  Box,
  CardContent,
  Container,
  Link,
  Typography,
} from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import { D } from "@mobily/ts-belt";
import * as React from "react";
import { useHistory } from "react-router-dom";
import { useOrder } from "../../hooks";
import Card from "./Card";
import * as styles from "./styles";

const loadingUI = (
  <styles.StyledCard>
    <styles.StyledMedia>
      <Skeleton variant="rect" height={280} />
    </styles.StyledMedia>

    <CardContent>
      <Skeleton variant="text" height="3rem" />

      <Box mt={1}>
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
      </Box>

      <Box mt={3}>
        <styles.FulfillmentCard style={{ height: 142 }}>
          <CardContent>
            <Box mb={2} display="flex" gridGap="16px" alignItems="center">
              <div>
                <Skeleton variant="circle" width={32} height={32} />
              </div>
              <Skeleton variant="text" height="2rem" width="50%" />
            </Box>

            <Skeleton variant="rect" height="2.5rem" width="75%" />
          </CardContent>
        </styles.FulfillmentCard>
      </Box>
    </CardContent>
  </styles.StyledCard>
);

export default function Audio() {
  const history = useHistory();

  const autographPurchaseId = React.useMemo(() => {
    const params = new URLSearchParams(history.location.search);
    const apid = params.get("apid");

    if (apid) {
      return apid;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { order, isLoading, isError } = useOrder(autographPurchaseId);

  return (
    <Container maxWidth="md">
      <Box
        alignItems="center"
        display="flex"
        justifyContent="center"
        minHeight="65vh"
        py={5}
      >
        {isError && (
          <Typography>
            Unable to load audio shoutout. Please{" "}
            <Link href="mailto:support@streamily.com">contact support.</Link>
          </Typography>
        )}

        {isLoading && loadingUI}

        {!isLoading && !isError && D.isNotEmpty(order) && (
          <Card order={order} />
        )}
      </Box>
    </Container>
  );
}
